﻿$bullish-color: #26a69a;
$bearish-color: #ef5350;
$default-color: #ffffff;

body .is-bullish,
body span.is-bullish,
body.is-dark .dark-inverted.is-bullish {
    color: $bullish-color !important;
}

body .is-bearish,
body span.is-bearish,
body.is-dark .dark-inverted.is-bearish {
    color: $bearish-color !important;
}

.is-text-underline {
    text-decoration: underline;
}

body {
    color: $default-color;
}

.is-scrollable {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.logo-asset img.base-currency, .logo-asset img.qoute-currency {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.logo-asset img.base-currency, .logo-asset img.qoute-currency {
    border: 1px solid #ffffff !important;
}

.is-dark .logo-asset img.base-currency, .is-dark .logo-asset img.qoute-currency {
    border: 1px solid #1e222d !important;
}

.logo-asset img.base-currency {
    position: relative;
}

.logo-asset img.qoute-currency {
    margin-left: -8px;
    margin-bottom: -8px;
}

.logo-large-asset img.base-currency, .logo-large-asset img.qoute-currency {
    border-radius: 50%;
    width: 38px;
    height: 38px;
}

.logo-large-asset img.base-currency, .logo-large-asset img.qoute-currency {
    border: 1px solid #ffffff !important;
}

.is-dark .logo-large-asset img.base-currency, .is-dark .logo-large-asset img.qoute-currency {
    border: 1px solid #1e222d !important;
}

.logo-large-asset img.base-currency {
    position: relative;
}

.logo-large-asset img.qoute-currency {
    margin-left: 20px;
    margin-top: -20px;
}

.error-text {
    color: #e62965 !important;
}
