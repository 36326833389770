.navbar-menu {
    background-color: #000 !important;
}

.mobile-navbar .brand-end .h-avatar.avatar-mobile > span > span {
    font-size: 0.9rem;
    line-height: 2.5em;
    margin-left: 8px;
}

.mobile-main-sidebar.has-labels .inner .icon-side-menu li a::after,
.mobile-main-sidebar.has-labels .inner .bottom-menu li a::after {
    content: attr(data-content);
    position: absolute;
    bottom: -8px;
    left: -29px;
    right: 0;
    margin: 0 auto;
    font-family: "Roboto",sans-serif;
    font-size: .5rem;
    font-weight: 500;
    color: #8a8b94;
    text-transform: uppercase;
    text-align: center;
    width: 80px;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}


ul.icon-menu li.is-active,
ul.bottom-menu li.is-active {
    border-left: 4px solid #671cc9;
}

ul.icon-side-menu li.is-active {
    border-left: 2px solid #671cc9;
}

ul.icon-menu li.is-active a::after,
ul.bottom-menu li.is-active a::after,
ul.icon-side-menu li.is-active a::after {
    color: #671cc9 !important;
}