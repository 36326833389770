.is-client.profile-wrapper .profile-body .profile-card .profile-card-section .section-content .recommendations-wrapper .recommendations-item .meta span:first-child {
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
    color: #283252;
    font-size: .95rem;
}

body.is-dark .profile-wrapper .profile-body .profile-card .profile-card-section .section-content .recommendations-wrapper .recommendations-item .meta span:first-child {
    color: #aaaab3 !important;
}

.is-client.profile-wrapper .profile-body .profile-card .profile-card-section .section-content .recommendations-wrapper .recommendations-item p {
    font-size: .9rem;
}

.is-client .recommendations-item .h-avatar.is-large {
    max-width: 92px;
    min-width: 92px;
}

.is-client .recommendations-item .h-avatar.is-large .avatar {
    width: 110px;
    min-width: 75px;
    height: 120px;
}

.is-client .recommendations-item .buttons {
    justify-content: space-around;
}