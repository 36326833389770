.detail-checkout {
    max-width: 940px !important;
}

.product-detail .price {
    margin-top: 10px;
}

.product-detail .price span:first-child {
    font-size: 2.3rem;
    font-weight: 600;
    color: #283252;
    line-height: 1;
}

.promotion-page-wrapper .wrapper-outer .side-wrapper .side-inner .side-title h3 {
    font-size: 1.5em;
}

.detail-checkout .radio-pills {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    //-webkit-box-pack: justify;
    //-ms-flex-pack: justify;
    //justify-content: space-between;
}

.detail-checkout .radio-pills .radio-pill {
    position: relative;
    margin-right: 10px;
}

.detail-checkout .radio-pills .radio-pill input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.detail-checkout .radio-pills .radio-pill input:checked + .radio-pill-inner {
    background: #671cc9;
    border-color: #671cc9;
    color: #fff;
}

.detail-checkout .radio-pills .radio-pill .radio-pill-inner {
    background: #671cc9;
    border-color: #671cc9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 110px;
    height: 50px;
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    font-family: "Roboto",sans-serif;
    font-weight: 600;
    font-size: .9rem;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.promotion-page-wrapper {
    min-height: 400px !important;
}

.promotion-page-wrapper.description-product {
    max-width: 940px !important;
    min-height: 0px !important;
}

.checkout-register .signup-wrapper {
    position: relative;
    min-height: auto !important;
    background: transparent !important;
}
