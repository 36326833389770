.Dropzone {
    height: 100%;
    width: 95%;
    background-color: #fff;
    border: 2px dashed #4267b2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 10px;
}

    .Dropzone:hover {
        background-color: #ecf0f7;
        border: 2px dashed #4267b2;
    }

.Highlight {
    background-color: rgb(188, 185, 236);
}

.Icon {
    opacity: 0.5;
    height: 32px;
    width: 32px;
    margin: 32px 0px;
}

.FileInput {
    display: none;
}
