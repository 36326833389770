@charset "utf-8";

// Set your brand colors
$dark: #1f2638;
$primary: #671cc9;
$background-color: #000000;
$border-color: #465784;
$table-border-color: #465784;
$component-background-color: #808080;
$default-color: #ffffff;
$text: #ffffff;
$text-invert: #000000;

$radius: 0.5rem;
$title-color: $text;
$body-background-color: $background-color;
$table-background-color: $background-color;
$table-row-hover-background-color: $dark;
$table-head-background-color: $dark;
$table-head-cell-color: #ffffff;
$table-color: #ffffff;
$table-foot-cell-color: #ffffff;
$table-cell-border: 1px solid $table-border-color;
$navbar-breakpoint: 1024px;

$dropdown-content-radius: 0px;
$dropdown-content-offset: 0px;
$dropdown-content-background-color: $dark;

$card-color: $text-invert;
$card-content-padding: 1.5rem;

// Import only what you need from Bulma
@import '../../node_modules/bulma/sass/utilities/_all.sass';
@import '../../node_modules/bulma/sass/layout/_all.sass';
@import '../../node_modules/bulma/sass/grid/_all.sass';
@import '../../node_modules/bulma/sass/base/_all.sass';
@import '../../node_modules/bulma/sass/elements/_all.sass';
@import '../../node_modules/bulma/sass/components/_all.sass';
@import '../../node_modules/bulma/sass/helpers/_all.sass';
@import '../../node_modules/bulma/sass/form/_all.sass';
@import '../../node_modules/bulma-switch/dist/css/bulma-switch.sass';
@import './ruangtrader.scss';
//@import './fonts.scss';

// Margin helper
$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: ( ('none', 0), ('xxs', 0.125), ('xs', 0.25), ('sm', 0.5), ('md', 1), ('lg', 2), ('xl', 4), ('xxl', 8) );
$positions: (('t', 'top'), ('r', 'right'), ('b', 'bottom'), ('l', 'left'));

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
}

body {
    //font-family: 'PoppinsMedium' !important;
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);

    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }

    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }

    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);

        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }

        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
}
