.is-billing-table .flex-small {
    flex: 0.3 0.3 !important;
}

.is-billing-table .flex-medium {
    flex: 0.6 0.6 !important;
}

.is-billing-table .flex-table.min-height {
    min-height: 200px;
}

.is-billing-table .tabs li a {
    color: #989898;
}

.is-billing-table .dashboard-tile.is-featured span {
    color: #ffffff !important;
}